<script setup lang="ts">
const { currentTheme, toggleTheme } = useCurrentTheme();
</script>

<template>
  <button
    type="button"
    class="text-orange-500 dark:text-secondary-300"
    @click="toggleTheme()"
  >
    <Icon :name="currentTheme === 'dark' ? 'uil:moon' : 'uil:sun'" size="2em" />
  </button>
</template>
